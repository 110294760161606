import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

import './App.css';

function App() {

  const [endDate, setEndDate] = useState(moment.tz("2025-04-18 09:50", "yyyy-MM-DD HH:mm:ss", "Europe/Bucharest"))
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const countdownToDate = (date) => {
    const seconds = moment(date).diff(moment(), 'seconds');

    const days = seconds / 60 / 60 / 24;
    const hours = seconds / 60 / 60;
    const minutes = seconds / 60

    const daysLeft = Math.floor(days);
    const hoursLeft = Math.floor(((days * 60 * 60 * 24) - (daysLeft * 60 * 60 * 24)) / 60 / 60);
    const minutesLeft = Math.floor(((hours * 60 * 60) - (hoursLeft * 60 * 60) - (daysLeft * 60 * 60 * 24)) / 60);
    const secondsLeft = Math.floor((minutes * 60) - (minutesLeft * 60) - (daysLeft * 60 * 60 * 24) - (hoursLeft * 60 * 60));

    if (daysLeft < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      countdownToDate(endDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return (<div className='layout'>
    <div className="dots"></div>
    <div className='layerThree'></div>
    <div className='layerTwo'></div>
    <div className='layerOne'></div>
    <div className="card">
      <div className="days">{days} <span>days</span></div>
      <div className="time">
        <div className="hours">{hours.toString().padStart(2, 0)}:</div>
        <div className="minutes">{minutes.toString().padStart(2, 0)}:</div>
        <div className="seconds">{seconds.toString().padStart(2, 0)}</div>
      </div>
    </div>
  </div>
  );
}

export default App;
